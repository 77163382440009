import useSWR from "swr";
import type { UserRead } from "typescript-axios";
import { UsersApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { redirectIfNotAuthorized } from "src/lib/utils";
import * as amplitude from "@amplitude/analytics-browser";

const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);

interface UseCurrentUserProps {
  redirectIfNotLoggedIn?: boolean;
}

const useCurrentUser = (redirectIfNotLoggedIn) => {
  const fetcher = () =>
    USERS_API.readMeApiV1UsersMeGet()
      .then((res) => {
        if (!amplitude.getUserId()) {
          amplitude.setUserId(res.data.id);
        }
        return res.data
      })
      .catch((e) => {
        if (redirectIfNotLoggedIn) {
          redirectIfNotAuthorized(e);
        }
      });
  return useSWR<UserRead>(`/users/me`, fetcher, {
    refreshInterval: 1000 * 60 * 5,
    dedupingInterval: 1000 * 60 * 5,
  });
};

export default useCurrentUser;
