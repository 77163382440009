import { usePageContext } from "vike-react/usePageContext";
import { Link as ChakraLink } from "@chakra-ui/react";

function NavLink({ href, children, ...props }: { href: string; children: string }) {
  const pageContext = usePageContext();
  const { urlPathname } = pageContext;
  const isActive = href === "/" ? urlPathname === href : urlPathname.startsWith(href);
  return (
    <ChakraLink href={href} className={isActive ? "is-active" : undefined} {...props}>
      {children}
    </ChakraLink>
  );
}

export default NavLink
