import dayjs from 'dayjs';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import utcPlugin from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with utc and timezone plugins
dayjs.extend(utcPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(timezone);

export default dayjs;
